import React from "react"
import PropTypes from "prop-types"

const Page = ({ children }) => (
  <div className="page">
    <div className="heroStripe">
      <div className="container">
        {children}
      </div>
    </div>
  </div>
)

Page.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Page
